@import 'normalize.css/normalize';

menu,
ol {
	padding: 0;
}

ul {
	list-style: none;
	padding: 0;
	margin: 0;
}

dd {
	margin: 0;
}

body {
	background-color: var(--white);
	@mixin fontFamily font-body;
	-webkit-overflow-scrolling: touch;
}

body.scroll-disable {
	overflow: hidden !important;
}

fieldset {
	border: 0;
	padding: 0.01em 0 0 0;
	margin: 0;
	min-width: 0;
}

p {
	@mixin fontFamily font-body;
	font-size: 0.875rem;
}

.styled-link {
	border-bottom: 4px solid color-mod(var(--yellow-orange) alpha(0.5));
	cursor: pointer;

	&:hover {
		border-bottom-color: var(--yellow-orange);
	}
}

.trail-hover {
	position: relative;
	text-decoration: none;

	&:before {
		position: absolute;
		bottom: -10px;
		content: '';
		left: 0;
		right: 100%;
		height: 2px;
		background-color: var(--yellow-orange);
	}

	&:hover {
		color: var(--yellow-orange);

		&:before {
			animation: trailAnimation 0.75s;
		}
	}
}

.trail-hover-wrapper:hover {
	.trail-hover:before {
		animation: trailAnimation 0.75s;
	}
}

.scroll {
	overflow-y: auto;
	scrollbar-width: thin;
	scrollbar-color: var(--sea);

	&::-webkit-scrollbar {
		width: 8px;
		height: 4px;
	}

	&::-webkit-scrollbar-track {
		background-color: var(--very-light-pink);
	}

	&::-webkit-scrollbar-thumb {
		background: var(--sea);
		height: 10px;
		border-radius: 20px;
		border-bottom: 20px solid var(--very-light-pink);
		border-top: 20px solid var(--very-light-pink);
	}
}

@keyframes trailAnimation {
	0% {
		left: 0;
		right: 100%;
	}

	50% {
		right: 0;
		left: 0;
	}

	100% {
		right: 0;
		left: 100%;
	}
}
